<template>
<div>
  <div class="map-holder">
    <v-img
     :src="require('../../../assets/images/map.png')"
  ></v-img>
  </div>
  <div class="text-center" ><v-card flat tile class="map-img" color=""></v-card></div>
</div>
  
</template>
<script>
export default {
  name: "GoogleMap",


};
</script>
<style>
.map-img {
    background-image: url('https://pilongtermbackups.s3.amazonaws.com/Zamupay/images/bottomShape.png') !important;
    background-position: left center !important;

}
</style>
